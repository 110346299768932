// Theme color settings
$(document).ready(function () {

    $(document).on('click', "*[theme]", function (e) {
        e.preventDefault();
        var currentStyle = $(this).attr('theme');
        $('#theme').attr({ href: 'assets/css/colors/' + currentStyle + '.css' })
    });

    var currentTheme = get('theme');
    if (currentTheme) {
        $('#theme').attr({ href: 'assets/css/colors/' + currentTheme + '.css' });
    }
    // color selector
    $(document).on('click', '#themecolors a', function (e) {
        $('#themecolors li a').removeClass('working');
        $(this).addClass('working')
    });

});

function get(name) {

}
